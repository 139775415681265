import MenuService from '../service/MenuService';
import UsuarioService from '../service/administracao/UsuarioService';
import TraducaoService from '../service/TraducaoService';

const MenuMixin = {
  data : function () {
    return {
      service : null,
      usuarioService : null,
      traducaoService : null,
      permissoes: [],
      menu : [],
    };
  },
  created: function () {
    this.service = new MenuService();
    this.usuarioService = new UsuarioService();
    this.traducaoService = new TraducaoService();

    this.menuLoad();
  },
  methods: {
    async menuLoad () {
      console.log('menuLoad');
      const res = await this.service.get();

      const user = this.$root.user;

      const traducoes = await this.traducaoService.once();

      let data = res.data;
      let _menu = [
          {
              label: 'Menu', icon: 'pi pi-fw pi-sitemap',
              items : []
          }
      ];

      // Sorting menu
      data.sort((entry, entry1) => {
          if (!entry.sistema) return -1;
          if (!entry1) return 1;
          if (!entry1.sistema) return 1;

          return entry.sistema.label.localeCompare(entry1.sistema.NOMSIS);
      });
      data.map(
          (entry) => {
              if (!entry.sistema) return;
              let manutencao = entry.sistema.tarefas.filter((task) => task.CODTIPTAR == "MT");
              // manutencao = this.ordena(manutencao);
              let digitacao = entry.sistema.tarefas.filter((task) => task.CODTIPTAR == "DG");
              let consulta = entry.sistema.tarefas.filter((task) => task.CODTIPTAR == "CT");
              let processamento = entry.sistema.tarefas.filter((task) => task.CODTIPTAR == "PR");
              let relatorio = entry.sistema.tarefas.filter((task) => task.CODTIPTAR == "RL");

              let tarManutencao = manutencao ? this.ordena(this.menuItems(entry.sistema.NOMSIS, manutencao)) : [];
              let tarConsulta = consulta ? this.ordena(this.menuItems(entry.sistema.NOMSIS, consulta)) : [];
              let tarDigitacao = digitacao ? this.ordena(this.menuItems(entry.sistema.NOMSIS, digitacao)): [];
              let tarProcessamento = processamento ? this.ordena(this.menuItems(entry.sistema.NOMSIS, processamento)): [];
              let tarRelatorio = relatorio ? this.ordena(this.menuItems(entry.sistema.NOMSIS, relatorio)) : [];
              let subItensSistema = [];
              // Pegando as tarefas
              let mt = {
                label: traducoes['manutenção'] ?? 'Manutenção',
                icon: 'fa-solid fa-marker',
                items : tarManutencao.length > 0 ? tarManutencao : []
              };

              let ct = {
                label: traducoes['consulta'] ?? "Consulta",
                icon: 'fa-solid fa-search',
                items : tarConsulta.length > 0 ? tarConsulta : []
              };

              let dg = {
                label: traducoes['digitação'] ?? 'Digitação',
                icon: 'fa-regular fa-keyboard',
                items : tarDigitacao.length > 0 ? tarDigitacao: []
              };

              let pr = {
                label: traducoes['processamento'] ?? 'Processamento',
                icon: 'fa-solid fa-wrench',
                items : tarProcessamento.length > 0 ? tarProcessamento : []
              };

              let rl = {
                label: traducoes['relatório'] ?? 'Relatório',
                icon: 'fa-solid fa-file-lines',
                items : tarRelatorio.length > 0 ? tarRelatorio : []
              };

              // icon: 'fa-solid fa-file-lines'
              if(mt.items.length > 0){
                subItensSistema.push(mt);
              }
              if(ct.items.length > 0){
                subItensSistema.push(ct);
              }
              if(dg.items.length > 0){
                subItensSistema.push(dg);
              }
              if(pr.items.length > 0){
                subItensSistema.push(pr);
              }
              if(rl.items.length > 0){
                subItensSistema.push(rl);
              }

              let NomSis = entry.sistema.label.toLowerCase();
              let IcoSis = entry.sistema.ICOSIS.toLowerCase();

              NomSis = (NomSis[0].toUpperCase()+NomSis.substr(1)).normalize("NFD");

              let sistema = {
                label : NomSis,
                icon: IcoSis,
                items : subItensSistema
              };

              if (user?.isClient) {
                //_menu[0].items = subItensSistema;
                subItensSistema.map(
                  subItem => _menu[0].items.push(subItem)
                );
              }
              else {
                _menu[0].items.push(sistema);
              }
          }
      );


      // if (!user.isClient && user.isAdmin) {
      //   _menu[0].items.push({
      //     label: 'Drive',
      //     icon: 'fa-solid fa-hard-drive',
      //     to : "/drive"
      //   });
      // }

      this.menu = _menu;
    },

    ordena(itens){
      // console.log(itens);
      itens.sort((entry, entry1) => {
        if (!entry.label) return -1;
          if (!entry1) return 1;
          if (!entry1.label) return 1;

          return entry.label.localeCompare(entry1.label);

        // return 0; // A e B são iguais em termos de ordenação
      });

      return itens; // Retorna os itens ordenados
    },

    menuItems(sistema, menu) {
        this.permissoes = JSON.parse(localStorage.getItem("permissions"));

        if (!this.permissoes) {
          return [];
        }

        let retorno =  [];
        menu
        // .map((task) => {
        .forEach((task) => {
            // console.log("task >>>> ",task);
            // se a task.CODTAR existir no ngc da tarefa ent inclui ela
            if(this.permissoes.find(element => element.CODTAR === task.CODTAR)){
              // return{
              retorno.push({
                  label : this.toTitleCase(task.TITTAR ?? task.DESTAR),
                  to : "/" + (sistema.toLowerCase().replaceAll(" ", "-") + "/" + task.CODTAR.toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              })
            }
          }
        );

        return retorno;
    },

    toTitleCase(str) {
      if (!str) return "";

      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  }
};

export default MenuMixin;